// OutfitsData.js

const outfitsData = [
  {
    id: 1,
    image: "/assets/outfit/outfit-1.jpg",
    products: [5, 6, 7], // IDs des produits dans productsData.js
  },
  {
    id: 2,
    image: "/assets/outfit/outfit-2.jpg",
    products: [12, 11],
  },
  {
    id: 3,
    image: "/assets/outfit/outfit-3.jpg",
    products: [10, 9, 11, 8],
  },
  {
    id: 4,
    image: "/assets/outfit/outfit-4.jpg",
    products: [2, 5],
  },
  {
    id: 5,
    image: "/assets/outfit/outfit-5.jpg",
    products: [1],
  },
];

export default outfitsData;
