import "./styles/App.scss";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import ProductDetails from "./components/ProductDetails";
import Header from "./components/Header";
import Outfit from "./components/Outfit";
import Order from "./components/Order";
import OutfitDetails from "./components/OutfitDetails";
import FavoritePage from "./components/FavoritePage";
import Home from "./components/Home";
import { FavoritesProvider } from "./context/FavoritesContext";
import Footer from "./components/Footer";

function App() {
  return (
    <div className="App">
      <FavoritesProvider>
        <Router>
          <div>
            <Header />
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/products" element={<ProductDetails />} />
              <Route path="/outfit-idea" element={<Outfit />} />
              <Route path="/outfit-idea/:id" element={<OutfitDetails />} />
              <Route path="/order" element={<Order />} />
              <Route path="/favorites" element={<FavoritePage />} />
            </Routes>
            <Footer />
          </div>
        </Router>
      </FavoritesProvider>
    </div>
  );
}

export default App;
