import React from "react";
import { useParams, Link } from "react-router-dom";
import outfitsData from "../data/OutfitsData";
import productsData from "../data/ProductsData";
import { convertCNYtoUSD } from "../utils/currencyConversion";

const OutfitDetails = () => {
  let { id } = useParams();
  const outfit = outfitsData.find((outfit) => outfit.id === parseInt(id));

  // Utilisation de map sur le tableau d'IDs pour respecter l'ordre
  const outfitProducts = outfit.products.map((productId) =>
    productsData.find((product) => product.id === productId)
  );

  return (
    <div className="container">
      <div className="outfit__grid">
        <img
          className="outfit__grid__image"
          src={outfit.image}
          alt={outfit.title}
        />
        <div className="outfit__product">
          <h2 className="banner__title">Adopt the Look</h2>
          {outfitProducts.map((product) => (
            <div className="outfit__product__card" key={product.id}>
              <Link target="blank" to={product.link}>
                <img
                  className="outfit__product__image"
                  src={product.image}
                  alt={product.name}
                />
              </Link>
              <div>
                <p className="product__name">{product.name}</p>
                <p className="product__price">{`${convertCNYtoUSD(product.price)} $`}</p>
                <p className="product__colorway">
                  {product.colorway ? `(${product.colorway})` : ""}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default OutfitDetails;
