// FavoritesContext.js
import React, { createContext, useContext, useState, useEffect } from "react";

const FavoritesContext = createContext();

export const useFavorites = () => useContext(FavoritesContext);

export const FavoritesProvider = ({ children }) => {
  const [favorites, setFavorites] = useState(() => {
    // Charger les favoris du stockage local au démarrage de l'application
    const localData = localStorage.getItem("favorites");
    return localData ? JSON.parse(localData) : [];
  });

  useEffect(() => {
    // Sauvegarder les favoris dans le stockage local à chaque modification
    localStorage.setItem("favorites", JSON.stringify(favorites));
  }, [favorites]);

  const addFavorite = (product) => {
    setFavorites((prevFavorites) => [...prevFavorites, product]);
  };

  const removeFavorite = (productId) => {
    setFavorites((prevFavorites) =>
      prevFavorites.filter((p) => p.id !== productId)
    );
  };

  return (
    <FavoritesContext.Provider
      value={{ favorites, addFavorite, removeFavorite }}
    >
      {children}
    </FavoritesContext.Provider>
  );
};
