import React from "react";
import { Link } from "react-router-dom";
import outfitsData from "../data/OutfitsData";

const Outfit = () => {
  return (
    <div className="container">
      <h1 className="banner__title">Find your outfit</h1>
      <div className="outfit">
        {outfitsData.map((outfit) => (
          <div key={outfit.id}>
            <Link to={`/outfit-idea/${outfit.id}`}>
              <img
                className="outfit__image"
                src={outfit.image}
                alt={outfit.title}
              />
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Outfit;
