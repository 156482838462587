const exchangeRate = 0.14; // 1 CNY = 0.14 USD

export const convertCNYtoUSD = (priceInCNY) => {
  const priceInUSD = priceInCNY * exchangeRate;
  const roundedPrice = Math.ceil(priceInUSD * 2) / 2;
  if (roundedPrice % 1 === 0) {
    return roundedPrice.toString();
  } else {
    return roundedPrice.toFixed(2);
  }
};
