import React from "react";

export const Order = () => {
  return (
    <div className="container">
      <h1 className="banner__title">How to order</h1>
      <h2>I. Sign Up on Pandabuy</h2>
      <h2>II. Submit an order</h2>
      <h2>III. Complete order</h2>
      <h2>IV. Payment</h2>
      <h2>V. Delivery</h2>
    </div>
  );
};

export default Order;
