import React, { useState } from "react";
import { Link } from "react-router-dom";
import productsData from "../data/ProductsData";
import outfitsData from "../data/OutfitsData"; // Import des tenues
import { useFavorites } from "../context/FavoritesContext";
import "../styles/Product.scss";
import { convertCNYtoUSD } from "../utils/currencyConversion";
import { generateAllChinaBuyLink } from "../utils/generateAllChinaBuyLink";
import { generateSuperBuyLink } from "../utils/generateSuperBuy";
import { generateMuleBuyLink } from "../utils/generateMuleBuy";
import { generateHooBuyLink } from "../utils/generateHooBuy";
import { generateCNFansLink } from "../utils/generateCnFans";

const Home = () => {
  const { favorites, addFavorite, removeFavorite } = useFavorites();
  const previewProducts = productsData.slice(0, 4);
  const previewOutfits = outfitsData.slice(0, 4); // Limiter à 4 tenues
  const [hoveredProductId, setHoveredProductId] = useState(null);

  const toggleFavorite = (product) => {
    const isFavorite = favorites.some((fav) => fav.id === product.id);
    if (isFavorite) {
      removeFavorite(product.id);
    } else {
      addFavorite(product);
    }
  };

  return (
    <div className="container">
      {/* Section Produits */}
      <div className="banner">
        <h2 className="banner__title">Trending Clothes</h2>
        <Link to="/products" className="banner__more">
          See all
        </Link>
      </div>
      <div className="product__list">
        {previewProducts.map((product) => (
          <div
            key={product.id}
            className="product__card"
            onMouseEnter={() => setHoveredProductId(product.id)}
            onMouseLeave={() => setHoveredProductId(null)}
          >
            <Link target="blank" to={product.link}>
              <img
                className="product__image"
                src={product.image}
                alt={product.name}
                loading="lazy"
              />
            </Link>
            {hoveredProductId === product.id && (
              <div className="product__overlay">
                <a
                  href={generateAllChinaBuyLink(product.link)}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="product__link product__link--acb"
                >
                  AllChinaBuy
                </a>
                <a
                  href={generateSuperBuyLink(product.link)}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="product__link product__link--superbuy"
                >
                  SuperBuy
                </a>
                <a
                  href={generateHooBuyLink(product.link)}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="product__link product__link--hoobuy"
                >
                  HooBuy
                </a>
                <a
                  href={generateMuleBuyLink(product.link)}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="product__link product__link--mulebuy"
                >
                  MuleBuy
                </a>
                <a
                  href={generateCNFansLink(product.link)}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="product__link product__link--cnfans"
                >
                  CnFans
                </a>
              </div>
            )}
            <p className="product__name">{product.name}</p>
            <p className="product__price">{`${convertCNYtoUSD(product.price)} $`}</p>
            <p className="product__colorway">
              {product.colorway ? `(${product.colorway})` : ""}
            </p>
            <button
              className="product__favorite"
              onClick={() => toggleFavorite(product)}
              style={{ background: "none", border: "none", cursor: "pointer" }}
              aria-label={
                favorites.some((fav) => fav.id === product.id)
                  ? "Remove from favorites"
                  : "Add to favorites"
              }
            >
              <img
                className="product__favorite"
                src={
                  favorites.some((fav) => fav.id === product.id)
                    ? "/assets/liked.svg"
                    : "/assets/unliked.svg"
                }
                alt="Favorite"
                style={{ width: "24px", height: "24px" }}
              />
            </button>
          </div>
        ))}
      </div>
      <Link to="/products">
        <button className="product__button">See more products</button>
      </Link>

      {/* Section Tenues */}
      <section className="section-outfit">
        <div className="banner">
          <h2 className="banner__title">Trending Outfit</h2>
          <Link to="/outfit-idea" className="banner__more">
            See all
          </Link>
        </div>
        <div className="product__list">
          {previewOutfits.map((outfit) => (
            <div key={outfit.id} className="product__card">
              <Link to={`/outfit-idea/${outfit.id}`}>
                <img
                  className="product__image"
                  src={outfit.image}
                  alt={outfit.title}
                />
              </Link>
              <p className="outfit__title">{outfit.title}</p>
            </div>
          ))}
        </div>
        <Link to="/outfit-idea">
          <button className="product__button">See more outfits</button>
        </Link>
      </section>

      {/* Section Marques */}
      <div className="brand">
        <h2 className="brand__title">Shop your favorite brand</h2>
        <div className="brand__logo">
          <Link to="/products?brand=Adidas">
            <img alt="Adidas logo" src="/assets/logo_adidas.svg" />
          </Link>
          <Link to="/products?brand=Nike">
            <img alt="Nike logo" src="/assets/logo_nike.svg" />
          </Link>
          <Link to="/products?brand=Balenciaga">
            <img alt="Balenciaga logo" src="/assets/logo_balenciaga.svg" />
          </Link>
          <Link to="/products?brand=LV">
            <img alt="LV logo" src="/assets/logo_lv.svg" />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Home;
