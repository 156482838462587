const productsData = [
  {
    id: 1,
    name: "T-Shirt Balenciaga",
    category: "T-Shirt",
    color: "Black",
    brand: "Balenciaga",
    colorway: "2 colors available",
    price: 125, // CNY
    image: "/assets/products/Tshirt-Balenciaga-1.jpg",
    link: "https://weidian.com/item.html?itemId=7242303934&vc_cps_track=1459920903_0_0",
  },
  {
    id: 2,
    name: "Adidas Campus 00s",
    category: "Shoes",
    color: "",
    colorway: "35 colors available",
    brand: "Adidas",
    price: 160,
    image: "/assets/products/Adidas-Campus00s.webp",
    link: "https://pandabuy.allapp.link/co4tkfprogtreqrk3m90",
  },
  {
    id: 3,
    name: "T-Shirt AMI Paris",
    category: "T-Shirt",
    color: "",
    brand: "AMI",
    price: 138,
    image: "/assets/products/tshirt-ami.png",
    link: "https://pandabuy.allapp.link/co4tlp10b4mjcscn6b7g",
  },
  {
    id: 4,
    name: "Ralph Lauren Hoodie",
    category: "Hoodie",
    color: "",
    brand: "Ralph Lauren",
    price: 158,
    image: "/assets/products/Ralphlauren-hoodie.webp",
    link: "https://pandabuy.allapp.link/co4to9h0b4mjcscn6dcg",
  },
  {
    id: 5,
    name: "Ralph Lauren Sweat",
    category: "Sweat",
    color: "",
    brand: "Ralph Lauren",
    price: 118,
    image: "/assets/products/sweat-rl.jpg",
    link: "https://pandabuy.allapp.link/co4tv9ogpf6oar2jc230",
  },
  {
    id: 6,
    name: "Carhartt Pant",
    category: "Pants",
    color: "",
    brand: "Carhartt",
    price: 128,
    image: "/assets/products/jean-carhartt.jpg",
    link: "https://pandabuy.allapp.link/co4u33hrogtreqrk42kg",
  },
  {
    id: 7,
    name: "Nike Air Force 1",
    category: "Shoes",
    color: "",
    brand: "Nike",
    price: 120,
    image: "/assets/products/airforceone.png",
    link: "https://pandabuy.allapp.link/co4u8m90b4mjcscn6rbg",
  },
  {
    id: 8,
    name: "Sneaker Louis Vuitton Trainer",
    category: "Shoes",
    color: "",
    brand: "Louis Vuitton",
    price: 420,
    image: "/assets/products/lv-trainer.png",
    link: "https://pandabuy.allapp.link/co5ctj9rogtuqkqe3uc0",
  },
  {
    id: 9,
    name: "T-Shirt Louis Vuitton Green Logo",
    category: "T-Shirt",
    color: "",
    brand: "Louis Vuitton",
    price: 240,
    image: "/assets/products/tshirt-lv.jpg",
    link: "https://pandabuy.allapp.link/co5d0jnm8ngrr7t9fa5g",
  },
  {
    id: 10,
    name: "New Era Cap 59FIFTY",
    category: "Cap",
    color: "",
    brand: "New Era",
    price: 19,
    image: "/assets/products/new-era-cap.jpg",
    link: "https://pandabuy.allapp.link/co5d458gpf6pm1sjb040",
  },
  {
    id: 11,
    name: "Levis 501 Pant",
    category: "Pants",
    color: "",
    brand: "Levis",
    price: 120,
    image: "/assets/products/levis-501.jpeg",
    link: "https://pandabuy.allapp.link/co5d9hnm8ngrr7t9fjc0",
  },
  {
    id: 12,
    name: "Ralph Lauren Knitted Sweater",
    category: "Sweat",
    color: "",
    brand: "Ralph Lauren",
    price: 148,
    image: "/assets/products/ralphlauren-knit.avif",
    link: "https://pandabuy.allapp.link/co5dh7ogpf6pm1sjbbgg",
  },
];

export default productsData;
