import React, { useState, useEffect } from "react";
import { useLocation, Link } from "react-router-dom";
import productsData from "../data/ProductsData";
import "../styles/Product.scss";
import { useFavorites } from "../context/FavoritesContext";
import { convertCNYtoUSD } from "../utils/currencyConversion";
import { generateAllChinaBuyLink } from "../utils/generateAllChinaBuyLink";
import { generateSuperBuyLink } from "../utils/generateSuperBuy";
import { generateMuleBuyLink } from "../utils/generateMuleBuy";
import { generateHooBuyLink } from "../utils/generateHooBuy";
import { generateCNFansLink } from "../utils/generateCnFans";

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const ProductDetails = () => {
  const initialFilters = {
    price: null,
    category: "Category",
    searchName: "",
    brand: "Brand",
  };

  const query = useQuery();
  const brandFilterFromQuery = query.get("brand");

  useEffect(() => {
    if (brandFilterFromQuery) {
      setFilters((prevFilters) => ({
        ...prevFilters,
        brand: brandFilterFromQuery,
      }));
    }
  }, [brandFilterFromQuery]);

  const [filters, setFilters] = useState(initialFilters);
  const [categories, setCategories] = useState(["Category"]);
  const [brands, setBrands] = useState(["Brand"]);

  const { favorites, addFavorite, removeFavorite } = useFavorites();

  const [hoveredProductId, setHoveredProductId] = useState(null);

  useEffect(() => {
    const categorySet = new Set(
      productsData.map((product) => product.category)
    );
    const sortedCategories = Array.from(categorySet).sort(); // Tri alphabétique
    setCategories(["Category", ...sortedCategories]);

    const brandSet = new Set(productsData.map((product) => product.brand));
    const sortedBrands = Array.from(brandSet).sort(); // Tri alphabétique
    setBrands(["Brand", ...sortedBrands]);
  }, []);

  const filteredProducts = filterProducts(
    productsData,
    filters.category,
    filters.searchName,
    filters.brand
  );

  function filterProducts(products, categoryFilter, searchName, brandFilter) {
    return products.filter((product) => {
      return (
        (categoryFilter === "Category" ||
          product.category === categoryFilter) &&
        (brandFilter === "Brand" || product.brand === brandFilter) &&
        (searchName === "" ||
          product.name.toLowerCase().includes(searchName.toLowerCase()))
      );
    });
  }

  function handleFilterChange(e) {
    const { name, value } = e.target;
    setFilters((prevFilters) => ({ ...prevFilters, [name]: value }));
  }

  function handleResetFilters() {
    setFilters(initialFilters);
  }

  const toggleFavorite = (product) => {
    const isFavorite = favorites.some((fav) => fav.id === product.id);
    isFavorite ? removeFavorite(product.id) : addFavorite(product);
  };

  return (
    <div className="container">
      <div className="product">
        <h2 className="banner__title">Search & Filter</h2>
        <p className="product__desc">Find what you want, quickly</p>
        <div className="filter">
          <input
            className="filter__search"
            type="text"
            name="searchName"
            placeholder="Search"
            value={filters.searchName}
            onChange={handleFilterChange}
          />
          <select
            className="filter__select"
            name="category"
            value={filters.category}
            onChange={handleFilterChange}
          >
            {categories.map((category) => (
              <option key={category} value={category}>
                {category}
              </option>
            ))}
          </select>
          <select
            className="filter__select"
            name="brand"
            value={filters.brand}
            onChange={handleFilterChange}
          >
            {brands.map((brand) => (
              <option key={brand} value={brand}>
                {brand}
              </option>
            ))}
          </select>
          <button className="filter__reset" onClick={handleResetFilters}>
            Reset Filters
          </button>
        </div>
        <ul className="product__list">
          {filteredProducts.map((product) => (
            <li
              className="product__card"
              key={product.id}
              onMouseEnter={() => setHoveredProductId(product.id)}
              onMouseLeave={() => setHoveredProductId(null)}
            >
              <Link target="blank" to={product.link}>
                <img
                  className="product__image"
                  src={product.image}
                  alt={product.name}
                />
              </Link>
              {hoveredProductId === product.id && (
                <div className="product__overlay">
                  <a
                    href={generateAllChinaBuyLink(product.link)}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="product__link product__link--acb"
                  >
                    AllChinaBuy
                  </a>
                  <a
                    href={generateSuperBuyLink(product.link)}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="product__link product__link--superbuy"
                  >
                    SuperBuy
                  </a>
                  <a
                    href={generateHooBuyLink(product.link)}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="product__link product__link--hoobuy"
                  >
                    HooBuy
                  </a>
                  <a
                    href={generateMuleBuyLink(product.link)}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="product__link product__link--mulebuy"
                  >
                    MuleBuy
                  </a>
                  <a
                    href={generateCNFansLink(product.link)}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="product__link product__link--cnfans"
                  >
                    CnFans
                  </a>
                </div>
              )}
              <p className="product__name">{product.name}</p>
              <p className="product__price">{`${convertCNYtoUSD(product.price)} $`}</p>
              <p className="product__colorway">
                {product.colorway ? `(${product.colorway})` : ""}
              </p>
              <button
                onClick={() => toggleFavorite(product)}
                style={{
                  background: "none",
                  border: "none",
                  cursor: "pointer",
                }}
              >
                <img
                  loading="lazy"
                  className="product__favorite"
                  src={
                    favorites.some((fav) => fav.id === product.id)
                      ? "/assets/liked.svg"
                      : "/assets/unliked.svg"
                  }
                  alt="Favorite"
                  style={{ width: "24px", height: "24px" }}
                />
              </button>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default ProductDetails;
